<template>
  <div v-if="completed">
    <router-view/>
  </div>
  <div v-else>
    <progress class="centered" style="width:80%" :value="porcentage" max="100"></progress>
  </div>
</template>


<script>
export default {
  data() {
    return {
      porcentage: 0,
      completed: false
    }
  },
  methods: {
    increase() {
      if (this.porcentage >= 100) this.completed = true
      setTimeout(() => {
        this.porcentage += 5//Math.floor(Math.random() * 6) + 1
        if (!this.completed) this.increase()
      }, 100);
    }
  },
  mounted() {
    this.increase()
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
